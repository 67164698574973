<template>
  <div>
    <el-table stripe border :data="classifiers">
      <el-table-column label="Наименование" prop="name"></el-table-column>
      <el-table-column label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="float: right"
                @click="editClassifiers(raw)"
                type="warning"
                >Редактировать</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["classifiers"],
  name: "classifiersTable",
  data() {
    return {};
  },
  methods: {
    editClassifiers(raw) {
      console.log("raw");
      console.log(raw);
      this.$router.push(raw.row.namelink);
    },
  },
};
</script>

<style scoped></style>
