<template>
  <div>
    <h2>Справочники</h2>

    <classifiers-table :classifiers="classifiers"></classifiers-table>
  </div>
</template>

<script>
import ClassifiersTable from "@/components/admin/classifiers/classifiersTable";
export default {
  name: "classifiersMain",
  components: { ClassifiersTable },
  data() {
    return {
      classifiers: [
        {
          name: "Классификатор отходов",
          namelink: "edit",
        },
        {
          name: "Реестр БелНиц",
          namelink: "tabBelniz",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
